import React, { useState, useRef } from "react";
import {
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { MuiTelInput } from "mui-tel-input";
import { MuiOtpInput } from "mui-one-time-password-input";
import { Button } from "react-bootstrap";
import { useApi } from "hooks/useAPI";
import { loginUser } from "services/apis/newApis";
import { enqueueSnackbar } from "notistack";

export default function Login() {
  const [phone, setPhone] = useState("");
  const [mpin, setMpin] = useState("");
  const inputRef = useRef(null);

  const [loading, callAPI] = useApi(loginUser);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // Handle phone input change
  const handlePhoneChange = (newPhone) => {
    setPhone(newPhone);
  };

  const handleChangeMpin = (newValue) => {
    setMpin(newValue.replace(/[^0-9]/g, "")); // Keep only numeric input
  };

  const handleSubmit = () => {
    callAPI({
      data: {
        phone: phone,
        mpin: mpin,
      },
    })
      .then((res) => {
        localStorage.setItem("Authorization", "Bearer " + res.token);
        enqueueSnackbar("Successfully Logged In!", { variant: "success" });
        window.location.replace("/");
      })
      .catch((err) => {
        enqueueSnackbar("Phone number or MPIN is incorrect.", { variant: "error" });
      });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit();
    }
  };

  return (
    <>
      <Stack sx={{ marginBottom: "5vh" }}>
        <Typography variant="h4" sx={{ fontWeight: "700" }}>
          Welcome back!
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: "400" }}>
          Login into your account and start analysis.
        </Typography>
      </Stack>

      <Stack spacing={3} onKeyDown={handleKeyDown}>
        {/* Phone input */}
        <MuiTelInput
          value={phone}
          onChange={handlePhoneChange}
          defaultCountry="IN"
          fullWidth
          inputRef={inputRef}
          sx={{
            "& .MuiOutlinedInput-root": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#000",
                borderRadius: "0.5rem",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#000",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#000",
              },
            },
          }}
          inputProps={{
            inputMode: "numeric",
            pattern: "[0-9]*",
          }}
        />

        {/* MPIN input */}
        <Stack direction="row" spacing={2} sx={{ justifyContent: "center", display:"flex", alignContent:"center", alignItems:"center"}}>
          <Typography variant="h6" fontWeight="400">
            MPIN:&nbsp;
          </Typography>
          <MuiOtpInput
            value={mpin}
            onChange={handleChangeMpin}
            length={4}
            sx={{
              "& .MuiInputBase-input": {
                fontSize: "1.2rem",
                textAlign: "center",
                height:"2vh",
              },
            }}
            textFieldProps={{
              type: "tel",
              inputMode: "numeric",
            }}
          />
        </Stack>

        <Button
          variant="outline-primary"
          onClick={handleSubmit}
          style={{
            width: "100%",
            padding: "10px",
            fontWeight: "600",
            marginTop: "20px",
            borderRadius: "8px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            backgroundColor: "#000",
            color: "#fff",
          }}
        >
          Submit
        </Button>
      </Stack>
    </>
  );
}
