import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import {thunk} from "redux-thunk"; // Fixed import
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import debounce from "lodash.debounce";
import { batchedSubscribe } from "redux-batched-subscribe";

// Reducers
import strikeData from "./reducers/strikeData";
import userProfile from "./reducers/userProfile";
import menustate from "./reducers/menustate";
import stateManager from "./reducers/stateManager";
import pcr from "./reducers/pcr";
import bankNiftyData from "./reducers/bankNiftyData";
import niftyData from "./reducers/niftyData";
import finNiftyData from "./reducers/finNiftyData";
import connectedBroker from "./reducers/connectedBroker";
import spotData from "./reducers/spotData";
import symbolData from "./reducers/symbolData";
// Debounce notification
const debounceNotify = debounce((notify) => notify(), 50);

// Combine reducers
const rootReducer = combineReducers({
  bankNiftyData,
  strikeData,
  userProfile,
  menustate,
  stateManager,
  pcr,
  niftyData,
  finNiftyData,
  connectedBroker,
  spotData,
  symbolData,
});

// Persist configuration
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["userProfile", "stateManager", "strikeData"], // Avoid persisting large data
};

// Persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// DevTools extension with fallback
const composeEnhancers =
  (typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    process.env.NODE_ENV === "development" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      stateSanitizer: (state) => ({
        ...state,
        strikeData: "<<STRIKE DATA OMITTED>>",
      }),
    })) ||
  compose;

// Monitor middleware to warn about large state size
const monitorMiddleware = (store) => (next) => (action) => {
  const result = next(action);
  const stateSize = JSON.stringify(store.getState()).length;
  if (stateSize > 1e6) {
    console.warn("Redux state size exceeds 1MB");
  }
  return result;
};

// Create store
const store = createStore(
  persistedReducer,
  composeEnhancers(
    applyMiddleware(thunk, monitorMiddleware),
    batchedSubscribe(debounceNotify)
  )
);

// Persistor
const persistor = persistStore(store);

export { store, persistor };
