import React, { useEffect, useState, useMemo } from "react";
import { Typography, Button, Grid, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import logo from "assets/icons/logo.png";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import IconMenu from "./iconMenu";
import Badge from "@mui/material/Badge";

function Navbar() {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));

  const stateManager = useSelector((state) => state?.stateManager);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openPage, setOpenPage] = useState(
    stateManager?.state?.data?.pageOpen || "dashBoard"
  );

  useEffect(() => {
    setOpenPage(stateManager?.state?.data?.pageOpen || "dashBoard");
  }, [stateManager]);

  const handleNavigation = (pageName, path) => {
    const data = { pageOpen: pageName };
    dispatch({
      type: "STATEMANAGER",
      payload: { data },
    });
    navigate(path);
  };

  // Dynamic button configuration
  const navButtons = [
    { name: "dashBoard", label: "Dashboard", path: "/" },
    { name: "optionChain", label: "Option Chain", path: "/OptionChain" },
    { name: "multiOi", label: "Multi Oi", path: "/MultiOI" },
    { name: "tradersRoom", label: "Traders' Room", path: "/TradersRoom" },
    { name: "orders", label: "Orders", path: "/Orders" },
  ];

  const buttonStyle = useMemo(
    () => (page) => ({
      border: `0.1rem solid ${theme.palette.text.primary}`,
      fontSize: "0.75rem",
      color: page === openPage ? "#000" : "#fff",
      padding: "0.2rem 0.6rem",
      backgroundColor: page === openPage ? "#fff" : "#000",
      "&:hover": {
        borderColor: "#fff",
        color: "#fff",
      },
    }),
    [openPage, theme.palette.text.primary]
  );

  return (
    <>
      {/* Navbar Container */}
      <Grid
        container
        alignItems="center"
        sx={{
          backgroundColor: "#000",
          justifyContent: "space-between",
          padding: "0.5rem 1rem",
          alignContent: "center",
          height: "100%",
        }}
      >
        {/* Logo */}
        <Grid item xs={3} sm={2} md={2} sx={{display:"flex"}}>
        <img
              src={logo}
              alt="ChartingHQ logo"
              style={{ height: "2.5rem" }}
            />
          <Box
          
            sx={{
                backgroundColor: "#fff", 
                color: "#000",
                border: "1.5px solid white", 
                fontSize: "0.6rem",
                borderRadius: "6px",
                height:"1rem",
                textAlign:"center",
                alignContent:"center",
                display:"flex",
                justifyContent:"center", 
                padding:"0rem 0.5rem",
                background: "linear-gradient(to bottom right, #FFD700, #FFC107, #FFECB3)",
            }}
          >
         <Typography sx={{fontSize: "0.7rem", mt:"-0.15rem", fontWeight:"600",}}>Beta  V1.1</Typography>
          </Box>
        </Grid>

        {!(smDown || mdDown) && (
          <Grid
            item
            xs={7}
            sm={8}
            md={8}
            container
            justifyContent="center"
            spacing={2}
          >
            {navButtons.map(({ name, label, path }) => (
              <Grid item key={name}>
                <Button
                  variant="outlined"
                  sx={{
                    ...buttonStyle(name),
                    textTransform: "none",
                    fontWeight: "600",
                  }}
                  onClick={() => handleNavigation(name, path)}
                >
                  {label}
                </Button>
              </Grid>
            ))}
          </Grid>
        )}

        {/* Icon Menu */}
        <Grid
          item
          xs={2}
          sm={2}
          md={2}
          container
          justifyContent="flex-end"
          alignContent={"center"}
        >
          <IconMenu />
        </Grid>
      </Grid>

      {/* Mobile Navigation */}
      {(smDown || mdDown) && (
        <Grid
          container
          spacing={2}
          sx={{
            backgroundColor: "#000",
            padding: "0.5rem 1rem",
            justifyContent: "space-between",
            borderTop: "0.01rem solid #fff",
          }}
        >
          <Grid item xs={12} container justifyContent="center" spacing={0.1}>
            {navButtons.map(({ name, label, path }) =>
              name !== "tradersRoom" ? (
                <Grid item key={name}>
                  <Button
                    variant="outlined"
                    sx={{
                      ...buttonStyle(name),
                      textTransform: "none",
                      fontWeight: "600",
                      fontSize: "0.6rem",
                    }}
                    onClick={() => handleNavigation(name, path)}
                  >
                    {label}
                  </Button>
                </Grid>
              ) : null
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default Navbar;
