import axios from "axios";

export const getAuthTokenFromCookie = (key) => {
  const cookies = document.cookie.split(";");
  for (const element of cookies) {
    const keyValueCookies = element.split("=");
    if (keyValueCookies[0].trim() === key.trim() && keyValueCookies[1]) {
      return keyValueCookies[1];
    }
  }
};

export const deleteAllCookies = () => {
  const cookies = document.cookie.split(";");

  for (const element of cookies) {
    const cookie = element;
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
};

export const ResponseHandler = ({
  method,
  endpoint,
  params,
  data,
  headers,
}) =>
  new Promise((resolve, reject) => {
    axios({
      method,
      url: endpoint,
      params,
      headers: {
        "Access-Control-Allow-Origin": [process.env.REACT_APP_BACKEND_BASE_URL],
        "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
        "Access-Control-Allow-Headers":
          "Origin, X-Requested-With, Content-Type, Accept, Authorization",
        Authorization: localStorage.getItem("Authorization"),
        ...headers,
      },
      data: data,
      // baseURL: "http://localhost:8080",
      baseURL: "https://node-backend.chartinghq.com",
      // baseURL: "https://api-test.chartinghq.com",
    })
      .then((res) => {

        if (res?.status === 201 || res?.status === 200) {
          resolve(res.data);
        } else {
          reject(res?.data?.Message || "Unexpected Error Occured");
        }
      })
      .catch((err) => {
        // console.log(err,"EWEW");
        if (err?.response?.status === 401 || err?.response?.status === 403) {
          reject("Session Terminated");
          localStorage.clear();
          sessionStorage.clear();
          window.location.reload();
        }
        reject(
          err?.response?.data?.error ||
            err?.message ||
            JSON.stringify(err)
        );
      });
      console.log(process.env,"process.env.NODE_ENV");
  });
