import React, { Suspense, useState } from "react";
import { Box } from "@mui/material";
import Navbar from "../Common/Navbar";
import { Navigate, Route, Routes } from "react-router-dom";
import ErrorBoundary from "hooks/errorBoundary";
import TickerTape from "components/Common/TickerTape2";
import SkeletonLoading from "components/Common/Skeletons/OptionChain";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const Dashboard = React.lazy(() => import("../Dashboard"));
const TradersRoom = React.lazy(() => import("../TradersRoom"));
const OptionChain = React.lazy(() => import("../OptionChain"));
const MultiOI = React.lazy(() => import("../MultiOi"));
const Orders = React.lazy(() => import("../OrdersPage"));

const Main = () => {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#E8E8EA",
        height: smDown ? "100%" : "100vh",
      }}
    >
      {smDown ? (
        <>
          <Box sx={{ zIndex: 1, borderBottom: "0.12rem solid #fff" }}>
            <TickerTape />
          </Box>
          <Box sx={{ marginBottom: "0.04rem", zIndex: 1 }}>
            <Navbar />
          </Box>
        </>
      ) : (
        <>
          <Box sx={{ marginBottom: "0.04rem", zIndex: 1 }}>
            <Navbar />
          </Box>
          <Box sx={{ zIndex: 1 }}>
            <TickerTape />
          </Box>
        </>
      )}

      <Box
        sx={{
          overflowY: "auto",
          justifyContent: "display",
          alignContent: "center",
          padding: "1rem",
        }}
      >
        <Suspense fallback={<SkeletonLoading />}>
          <ErrorBoundary>
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/OptionChain/*" element={<OptionChain />} />
              <Route path="/MultiOI/*" element={<MultiOI />} />
              <Route path="*" element={<Navigate to="/" replace />} />
              <Route path="/TradersRoom" element={<TradersRoom />} />
              <Route path="/Orders" element={<Orders />} />
            </Routes>
          </ErrorBoundary>
        </Suspense>
      </Box>
    </Box>
  );
};

export default Main;
